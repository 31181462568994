import React from "react";
import Layout from "../../components/Layout";
import { Helmet } from "react-helmet";

export default () => {
  const title = "About Us and Our Values | Schoolhouse by the Sea";
  const description =
    "Here's some information about what Schoolhouse by the Sea offers and our methodologies.";
  return (
    <Layout>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={description} />
      </Helmet>
      {/*<div*/}
      {/*    className="full-width-image-container"*/}
      {/*    style={{*/}
      {/*        backgroundImage: `url('/img/seafoam-splash.jpg')`,*/}
      {/*    }}*/}
      {/*>*/}

      {/*</div>*/}
      <section className="section">
        <div className="container">
          <div className="columns">
            <div className="column is-10 is-offset-1">
              <div className="content">
                <h1>About Us and Our Values</h1>
                <h2>What We Offer</h2>
                <p>
                  Schoolhouse by the Sea is an educational resource center that
                  publishes videos, articles, and other media for children and
                  their caring adults. Our materials are designed to supplement
                  learning at home and to support homeschooling, as well as
                  distance learning scenarios. The bulk of our resources are
                  developed with preschool and elementary-aged children in mind,
                  but older learners may find valuable resources, too.
                  <br />
                  <br />
                  Over the course of the school year, we look forward to
                  publishing a variety of resources. These include songs,
                  rhymes, activities, crafts, games, recipes, handouts, and
                  curriculum-planning materials. We will offer learning
                  activities to support childrens’ growth in English Language
                  Arts, Math, Science, Social-Emotional Learning and the Arts.
                  We will also offer resources for adults, including
                  explanations of our activities, parent and educator supports,
                  and suggested resources.
                  <br />
                  <br />
                  We strive to provide as many of our materials for free as
                  possible to ensure equitable access. If you find these
                  resources valuable and are able to offer a one-time or monthly
                  donation, you will help us keep our materials accessible to
                  all as well as ad free! Support us monthly through{" "}
                  <a href="https://www.patreon.com/schoolhousebythesea">
                    Patreon
                  </a>{" "}
                  or with a one-time contribution with{" "}
                  <a href="https://paypal.me/metztechco">PayPal</a>.
                </p>
                <h2>Methodology</h2>
                <p>
                  Drawing on a background in social-emotional learning,
                  curriculum planning, traditional classroom teaching, and
                  Waldorf methodology, Schoolhouse by the Sea emphasizes
                  creativity, movement, and integrated learning. Our activities
                  are designed to support and inspire educators and learners
                  alike. We provide the caring adults (educators) in a child’s
                  life with the foundations and background of an activity, as
                  well as providing structures for hands-on, integrative
                  learning.
                  <br />
                  <br />
                  We encourage families to do art, make music, ask questions,
                  wonder at nature, and grow together. We also know that this is
                  a strange and difficult time for many families, so we intend
                  to create materials that students can follow independently
                  while the adults in their life are otherwise engaged. Our
                  intention is to partner with families to provide resources
                  that meet a variety of needs. If you have a specific request,
                  please contact us. We look forward to hearing from you!
                </p>
                <h2>Our Values</h2>
                <p>
                  Homeschooling, facilitating distance learning, and even
                  supplementing in-school education are incredibly rewarding
                  tasks, but they are also tremendously challenging. At a time
                  when so many of us are overwhelmed by the enormity of our
                  global (and personal) challenges, our goal is to be a resource
                  and a support.
                  <br />
                  <br />
                  At Schoolhouse by the Sea, we celebrate diversity and
                  inclusion. Each child, each family, and each community have
                  unique strengths. We see education as a lifelong process that
                  fosters continual growth, holistic development, and a sense of
                  wonder. We hope to help you find (or deepen) your joy in
                  learning and teaching!
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};
